<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffReport.bilateral_trade_report')}} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="">
            <b-form-group
              label-for="fiscal_year_id"
              slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }}
                </template>
                <b-form-select
                  plain
                  id="fiscal_year_id"
                  :options="fiscalYearList"
                  v-model="search.fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
          </b-col>
         <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Targeted Country" vid="targeted_country_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  label-for="targeted_country_id"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffApp.targeted_country')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.targeted_country_id"
                  :options="tradeTariffCountryList"
                  id="targeted_country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Associated Country" vid="associated_country_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  label-for="associated_country_id"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffApp.associated_country')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.associated_country_id"
                  :options="tradeTariffCountryList"
                  id="associated_country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
              <b-col sm="9">
              </b-col>
              <b-col sm="3" style="text-align: right;">
                <b-button size="sm" type="submit" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('tradeTariffReport.bilateral_trade_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
        <div>
          <b-row class="text-right mt-2">
            <b-col>
              <b-button @click="pdfExport" class="btn btn-success btn-md">
                <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
              </b-button>
            </b-col>
          </b-row>
          <div class="table-wrapper table-responsive" >
            <ReportHeading />
            <table class="table table-striped table-hover table-bordered">
            <tr>
              <th colspan="15" style="text-align: center;">{{ $t('tradeTariffReport.bilateral_trade_report') }}</th>
            </tr>
            <tr>
              <th>{{$t('globalTrans.sl_no')}}</th>
              <th>{{$t('globalTrans.fiscal_year')}}</th>
              <th>{{$t('tradeTariffReport.export_in_usd')}}</th>
              <th>{{$t('tradeTariffReport.import_in_usd')}}</th>
            </tr>
              <template v-if="itemList && itemList.length > 0">
              <tr v-for="(item,index) in itemList" :key="index">
                <td>{{ $n(index + 1) }}</td>
                <td>{{ getFiscalYear(item.fiscal_year_id) }}</td>
                <td class="text-right">{{ $n(item.total_export) }}</td>
                <td class="text-right">{{ $n(item.total_import) }}</td>
              </tr>
              </template>
              <template v-else>
                  <tr>
                      <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                  </tr>
              </template>
          </table>
          </div>
        </div>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { bilateralTradeReport } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        targeted_country_id: 0,
        associated_country_id: 0,
        fiscal_year_id: 0,
        limit: 10
      },
      viewitemId: 0,
      itemList: []
    }
  },
  created () {
    // this.loadData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    viewTitle () {
      return this.$t('tradeTariffReport.hs_code') + ' ' + this.$t('globalTrans.details')
    }
  },
  watch: {
    'search.section_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chapterList = this.getChapterList(newVal)
      } else {
        this.chapterList = []
      }
    }
  },
  methods: {
    getFiscalYear (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.itemList = []
      this.searchType = 0
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, bilateralTradeReport, params)
      if (result.success) {
        this.itemList = result.data.data
      } else {
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfExport () {
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7 })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, bilateralTradeReport, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
